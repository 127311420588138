* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-y: hidden; 
  overflow-x: hidden; 
}

body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #090909;
}

.wrapper {
  margin: auto;
  margin-top: -20px;
  display: flex;
}

@keyframes transparency {
    0% {
        opacity: 100%;
        color: #fff;
    }
    10% {
        color: #1bfe46;
        opacity: 100%;
    }
    80% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}

// .column {
//   min-width: 20px;
//   padding: 0px 0px;
//   color: #1bfe46;
//   writing-mode: vertical-lr; 
//   text-orientation: upright;
//   font-family: 'Atomic Age', cursive;
//   font-weight: 800;
//   font-size: 20px;
//   line-height: 1;
//   user-select: none;
// }

.field {
  min-width: 20px;
  padding: 0px 0px;
  font-family: 'Atomic Age', cursive;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  color: #090909;
  line-height: 1;
  user-select: none;
  animation: transparency 4s ease;
}